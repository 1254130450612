.img-container {
  height: 100vh;
  width: 85vh;
  text-align: center;
}
#img {
  cursor: pointer;
  max-width: 100%;
}

.canvas {
  cursor: pointer;
}

.lh-40 {
  line-height: 40px;
}
